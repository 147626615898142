import { bigEcho } from "./bigEcho";
import { bitcrusher } from "./bitCrusher";
import { eq } from "./eq";
import { pitchShifter } from "./pitchShifter";
import { reverb } from "./reverb";

export const effects = {
  reverb,
  bigEcho,
  bitcrusher,
  eq,
  pitchShifter,
};

export function SoundWrapper(audioSource, audioContext) {
  this.audioSource = audioSource;
  this.audioContext = audioContext;
  this.effect = {};
  this.settings = {};
  this.processedBuffer = [];
  this.processedBufferQueue = [];
  this.workletNode = null;
}

// Static property to keep track of loaded modules
SoundWrapper.loadedModules = new Set();

// Method to set parameters
SoundWrapper.prototype.setParameters = function (config) {
  console.log("SoundWrapper - setParameters:", config);
  if (!this.workletNode) {
    console.error("No effect node is set.");
    return;
  }

  for (const [key, value] of Object.entries(config)) {
    if (this.workletNode.parameters.has(key)) {
      this.workletNode.parameters
        .get(key)
        .setValueAtTime(value, this.audioContext.currentTime);
    } else {
      console.warn(`Parameter ${key} not found on the worklet node.`);
    }
  }
};

// Method to add effect
SoundWrapper.prototype.addEffect = async function (effectName, config) {
  console.log("SoundWrapper - addEffect:", config);
  const effect = effects[effectName];
  if (!effect) {
    console.error(`Effect '${effectName}' not found.`);
    return null;
  }

  this.settings = effect.settings;
  this.parameters = effect.parameters;

  // Load the AudioWorklet module if not already loaded
  if (!SoundWrapper.loadedModules.has(effect.moduleUrl)) {
    try {
      await this.audioContext.audioWorklet.addModule(effect.moduleUrl);
      SoundWrapper.loadedModules.add(effect.moduleUrl);
    } catch (error) {
      console.error(`Failed to load module ${effect.moduleUrl}:`, error);
      return null;
    }
  }

  // Create the AudioWorkletNode
  this.workletNode = new AudioWorkletNode(
    this.audioContext,
    effect.processorName,
    {
      outputChannelCount: [1],
    }
  );

  // Set initial parameters
  this.setParameters(config);

  // Disconnect the audio source from previous connections
  this.audioSource.disconnect();
  // Connect the nodes
  this.audioSource.connect(this.workletNode);
  this.workletNode.connect(this.audioContext.destination);

  // Return the workletNode
  return this.workletNode;
};

// Method to remove effect
SoundWrapper.prototype.removeEffect = function () {
  console.log("SoundWrapper - removeEffect");

  if (this.workletNode) {
    this.workletNode.disconnect();
    this.audioSource.disconnect(this.workletNode);
    this.workletNode = null;
  }

  this.effect = {};
  this.settings = {};
  this.parameters = null;
  this.processedBufferQueue = [];

  // Reconnect the audio source directly to the destination
  this.audioSource.connect(this.audioContext.destination);
};
